import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { capitalize } from 'lodash-es';

import PageHeader from '../components/PageHeader';
import Post from '../containers/Post';
import SiteTitle from '../containers/SiteTitle';
import Layout from '../layouts';
import { scale } from '../utils/typography';

const SubHeader = styled('span')({
  ...scale(1 / 4),
  textTransform: 'uppercase',
  letterSpacing: '2px',
});

const Category = ({ pageContext, data, location }) => {
  const { category } = pageContext;
  const {
    allMarkdownRemark: { edges, totalCount },
  } = data;

  return (
    <Layout location={location}>
      <SiteTitle title={capitalize(category)} />
      <header>
        <SubHeader>category</SubHeader>
        <PageHeader header={capitalize(category)} />
      </header>

      <section>
        {edges.map(({ node }) => (
          <Post key={node.fields.slug} node={node} />
        ))}
      </section>
    </Layout>
  );
};

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Category;
export const pageQuery = graphql`
  query CategoryPage($category: String!) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          ...PostFragment
        }
      }
    }
  }
`;
